.hamburger {
  &::before {
    @apply content-[''] absolute bg-white left-3 top-5 w-8 h-0.5;
    box-shadow: 0 7px 0 0 #fff, 0 14px 0 0 #fff;
  }
}

@screen sm {
  .hamburger {
    &::before {
      @apply content-none;
    }
  }
}
