.confirmationDeleteClientList {
  @apply rounded px-9 pb-8 pt-9 sm:max-w-[400px] #{!important};
}
.confirmationDeleteClientList > span {
  @apply hidden;
}
.confirmationDeleteClientList h3.title {
  @apply text-base font-medium tracking-5xl text-gray-400;
}
.confirmationDeleteClientList p {
  @apply block pb-10 font-light tracking-10xl;
}
.confirmationDeleteClientList .buttonContainer {
  @apply flex justify-between;
}
.confirmationDeleteClientList button {
  @apply w-[150px] py-1;
}
.confirmationDeleteClientList button:last-child {
  @apply text-white #{!important};
}
.confirmationDeleteClientList button span {
  @apply text-xs;
}
