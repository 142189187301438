/*
  ANALYSIS - TERRITORY
*/

.analysis .recAnalysisTerritory {
  @apply col-span-full w-full;

  .territoryList {
    @apply z-0 w-full flex-none base:w-1/3 base:pr-5;
  }

  .territoryList ul {
    @apply base:max-h-[905px] base:overflow-y-auto;
  }

  .territoryList ul::-webkit-scrollbar {
    width: 6px;
  }

  .territoryList ul::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 0);
    border-radius: 3px;
  }

  .territoryList ul:hover::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 1);
  }

  .territoryList ul::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0);
    border-radius: 3px;
  }

  .territoryList:hover ul::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.5);
  }

  .territoryList ul::-webkit-scrollbar-thumb:hover {
    background: rgba(136, 136, 136, 1);
  }

  .territoryList .territoryListItem .summary {
    @apply relative flex h-[60px] cursor-pointer items-center justify-between border-b border-gray-100 px-5;
  }

  .territoryList .territoryListItem.active .percentage {
    @apply bg-gray-200;
  }

  .territoryList .territoryListItem .percentage {
    @apply absolute bottom-0 left-0 top-0 z-0 min-w-[1px] bg-limelight hover:bg-gray-200;
  }

  .analysis
    .analysisTerritory
    .territoryList
    .territoryListItem.active
    .percentage {
    @apply bg-green-600;
  }

  .territoryList .territoryListItem span {
    @apply z-10;
  }

  .columnChart {
    @apply px-5 base:hidden;
  }

  .columnChart .title {
    @apply pt-5 text-sm font-medium;
  }

  .columnChart .total {
    @apply pb-1 pt-4 text-xl;
  }

  .columnChart .legend {
    @apply mb-5;
  }

  .columnChart .legendItem {
    @apply flex items-center justify-between text-sm pb-3.5 pt-1.5;
  }

  .columnChart .icon {
    @apply mr-2.5 block h-2 w-2 rounded-full mb-5;
  }

  .columnChart .name {
    @apply leading-[18px];
    width: calc(100% - 150px);
  }

  .columnChart .value {
    @apply mb-4;
  }

  .columnChart .value span {
    @apply font-light;
  }
  .columnChart .percent {
    @apply ml-auto w-[44px] text-right mb-4;
  }

  .desktopChartsContainer {
    @apply hidden w-2/3 flex-none base:block;
  }

  .desktopChartsContainer b {
    @apply font-medium;
  }

  .pieChart {
    @apply flex justify-between pb-5;
    min-height: 355px;
  }

  .pieChart .chartContainer {
    @apply relative flex items-center justify-center;
  }

  .pieChart .chartInfo {
    @apply w-full pr-7;
  }

  .pieChart .legend li {
    @apply w-full;

    &:nth-child(1) .progressBar {
      background-color: rgba(248, 40, 177, 0.1);

      .progressTrack {
        background-color: #f828b1;
      }
    }

    &:nth-child(2) .progressBar {
      background-color: rgba(247, 176, 110, 0.15);

      .progressTrack {
        background-color: #f7b06e;
      }
    }

    &:nth-child(3) .progressBar {
      background-color: rgba(35, 164, 223, 0.15);

      .progressTrack {
        background-color: #23a4df;
      }
    }

    &:nth-child(4) .progressBar {
      background-color: rgba(58, 205, 50, 0.15);

      .progressTrack {
        background-color: #3acd32;
      }
    }

    &:nth-child(5) .progressBar {
      background-color: rgba(178, 225, 229, 0.2);

      .progressTrack {
        background-color: #b2e1e5;
      }
    }
  }

  .pieChart .legend .titleValuesWrapper {
    @apply px-0 pb-2.5 pt-3;
  }

  .pieChart .legend .productCode {
    @apply inline-block pl-1.5 pr-3.5 text-sm text-gray-400;
  }

  .pieChart .legend .configDesc {
    @apply inline-block pr-3.5 text-sm text-gray-400;
  }

  .pieChart .legend .progressBar {
    @apply mt-1.5 h-4 rounded-sm;
  }

  .pieChart .legend .progressTrack {
    @apply h-4 rounded-sm ease-in duration-500;
  }

  .pieChart .legend .name {
    @apply flex items-center;
  }

  .pieChart .legend .icon {
    @apply mr-2.5 block h-2.5 w-2.5 rounded-full;
  }

  .pieChart .legend .value {
    @apply flex flex-col text-right;
  }

  .mapChart .chartContainer {
    @apply border border-gray-100;
  }

  .mapChart path[data-country-code] {
    stroke: transparent;
  }

  .mapChart .legend {
    @apply flex;
  }

  .mapChart .heatmap {
    @apply mx-2.5 w-full rounded-sm;
    background: linear-gradient(to right, #A6A6A6, #D9D9D9);
  }
}
