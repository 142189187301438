.tabs nav a,
.tabs nav button {
  @apply inline-block tracking-base sm:tracking-lg cursor-pointer text-sm sm:text-base border-solid border-white border-b-2 pt-3 sm:pt-4 px-0 pb-2 sm:pb-4 align-middle mr-3 sm:mr-4;
}

.tabs nav a.active,
.tabs nav button.active,
.tabs nav a.active:hover,
.tabs nav button.active:hover {
  @apply text-midnight border-solid border-midnight no-underline border-b-2 font-normal;
}

.tabs nav a:hover,
.tabs nav button:hover {
  @apply text-midnight border-white no-underline border-b-2 font-normal;
}

.tabs nav svg {
  @apply w-8 h-8 mx-auto block base:my-2.5 lg:w-10 lg:h-10;
}

.tabs nav a.active svg,
.tabs nav button.active svg {
  @apply stroke-midnight fill-midnight;
}

.tabs nav a:hover svg,
.tabs nav button:hover svg {
  @apply base:stroke-midnight base:fill-midnight;
}

.tab-content {
  @apply hidden;
}

.tab-content.active {
  @apply block;
}

.tabs.boxedTabs {
  @apply min-w-[320px];
}

.tabs.boxedTabs nav {
  @apply flex items-start flex-nowrap justify-evenly;
}

.tabs.boxedTabs nav a,
.tabs.boxedTabs nav button {
  @apply inline-block text-3xs border-0 border-solid border-gray-100 text-center text-gray-400 my-0 ml-0 -mr-px pt-2 pb-2 sm:pb-2.5 sm:pt-2.5 sm:w-full sm:border base:text-sm;
}

.tabs.boxedTabs nav a.link p {
  @apply -mt-2 sm:mt-0;
}

.tabs.boxedTabs nav a.active,
.tabs.boxedTabs nav button.active {
  @apply border-0 text-midnight border-solid border-midnight z-[1] sm:border;
}

.tabs.boxedTabs nav a:hover,
.tabs.boxedTabs nav button:hover {
  @apply border-0 border-solid border-midnight z-10 sm:border base:text-midnight;
}
