@import "tailwindcss/components";

@layer components {
  /*
1. Buttons
2. Labels & Inputs
3. Select


______       _   _                  
| ___ \     | | | |                 
| |_/ /_   _| |_| |_ ___  _ __ 
| ___ \ | | | __| __/ _ \| '_ \
| |_/ / |_| | |_| || (_) | | | \
\____/ \__,_|\__|\__\___/|_| |_|                               
  */
  .button {
    @apply inline-flex cursor-pointer items-center justify-center whitespace-nowrap rounded-sm px-4 py-2 text-sm;
  }

  .button:disabled {
    @apply cursor-not-allowed bg-gray-400 text-gray-800 opacity-[0.65] hover:bg-gray-400 hover:text-gray-800;
  }

  .button:disabled svg {
    @apply fill-gray-200 !important;
  }

  .primaryCta {
    @apply bg-limelight text-midnight active:bg-limelight;
  }

  .primaryNarrowCta {
    @apply bg-limelight px-6 py-0 text-midnight active:bg-limelight;
  }

  .secondaryCta {
    @apply bg-gray-100 text-gray-800 hover:bg-limelight hover:text-midnight active:hover:bg-limelight;
  }

  .secondaryNarrowCta {
    @apply h-[30px] bg-gray-200 py-0 text-gray-800 hover:bg-limelight hover:text-midnight active:bg-gray-100;
  }

  .secondaryCta svg {
    @apply -ml-1;
  }

  .secondaryCta:hover svg {
    @apply stroke-white;
  }

  .cleanCta {
    @apply text-gray-800 hover:text-midnight;
  }

  .cleanCta svg {
    @apply stroke-gray-800;
  }

  .cleanCta:hover svg {
    @apply stroke-midnight;
  }

  .cleanLightCta {
    @apply text-gray-400 hover:text-midnight;
  }

  .outlineCta {
    @apply border border-gray-100 text-gray-800 hover:text-midnight;
  }

  .textLink {
    @apply text-base tracking-lg text-gray-800 underline hover:text-midnight;
  }

  .cleanCta:disabled,
  .outlineCta:disabled,
  .textLink:disabled {
    @apply bg-transparent hover:bg-transparent;
  }

  .dimmedCta {
    @apply bg-gray-100 px-2.5 py-2 text-xs tracking-lg text-gray-800 hover:text-midnight;
  }

  .errorCta {
    @apply bg-red-800 text-white active:bg-red-800;
  }
  
  .errorCta svg {
    @apply fill-white;
  }

  .errorCta:hover svg {
    @apply stroke-white;
  }

  /*
 _           _          _            _____                  _   
| |         | |        | |   ___    |_   _|                | |  
| |     __ _| |__   ___| |  ( _ )     | | _ __  _ __  _   _| |_ 
| |    / _` | '_ \ / _ \ |  / _ \/\   | || '_ \| '_ \| | | | __|
| |___| (_| | |_) |  __/ | | (_>  <  _| || | | | |_) | |_| | |_ 
\_____/\__,_|_.__/ \___|_|  \___/\/  \___/_| |_| .__/ \__,_|\__|
                                               | |              
                                               |_|              
*/
  input {
    @apply mt-2 h-11 w-full border border-gray-100 px-3 tracking-base text-gray-800 outline-gray-100 sm:tracking-xl;
    box-shadow: 0 0 0 50px #fff inset;
  }

  input::placeholder {
    @apply text-gray-400 opacity-100;
  }

  .loading input {
    @apply bg-form-loading-spinner bg-contain bg-right bg-no-repeat shadow-none;
  }

  input:disabled {
    @apply bg-white opacity-80;
  }

  input.error {
    @apply border-red-800;
  }

  .error input {
    @apply border-red-800;
  }

  label {
    @apply pb-1 pl-3 pr-2 pt-0 tracking-lg;
  }

  .error label {
    @apply text-red-800;
  }

  textarea {
    @apply mt-2 min-h-[70px] w-full border border-gray-100 px-3 pt-2 tracking-xl text-midnight outline-gray-100;
  }

  textarea:disabled {
    @apply bg-white opacity-80;
  }

  .error textarea {
    @apply border-red-800;
    background-color: #fadbd8;
  }

  .lightErrorMessage {
    @apply mt-2 text-base text-red-800;
  }

  .darkErrorMessage {
    @apply mt-2 text-base text-white;
  }

  .horizontalView {
    @apply grid items-center gap-x-4;
    grid-template-columns: 200px auto;
    grid-template-areas:
      "label input input"
      "....... error error";
  }

  .horizontalView label {
    @apply p-0;
    grid-area: label;
  }

  .horizontalView input,
  .horizontalView textarea {
    @apply m-0;
    grid-area: input;
  }

  .horizontalView .lightErrorMessage,
  .horizontalView .darkErrorMessage {
    grid-area: error;
  }

  @media screen and (max-width: 767px) {
    .horizontalView {
      grid-template-columns: 150px auto;
    }
  }

  @media screen and (max-width: 479px) {
    .horizontalView {
      @apply flex flex-col items-start;
    }
  }

  /*
______                     _                     
|  _  \                   | |                    
| | | |_ __ ___  _ __   __| | _____      ___ __  
| | | | '__/ _ \| '_ \ / _` |/ _ \ \ /\ / / '_ \ 
| |/ /| | | (_) | |_) | (_| | (_) \ V  V /| | | |
|___/ |_|  \___/| .__/ \__,_|\___/ \_/\_/ |_| |_|
                | |                              
                |_|                              
*/
  .dropdown {
    @apply relative;
  }

  .dropdown.selectDropdownFull > button {
    @apply relative h-11 border border-gray-100 bg-white pb-0 pl-3.5 pr-8 pt-0.5 text-left text-xs tracking-lg text-gray-800;
  }
  .dropdown.selectDropdownFull > button::after {
    @apply absolute right-2 inline-block h-0 w-0 border-x-transparent border-t-gray-400 text-xl text-limelight content-[''];
    border-top-width: 6px;
    border-left-width: 6px;
    border-right-width: 6px;
    top: 1.1rem;
  }
  .dropdown.selectDropdownFull > button:hover::after {
    @apply border-t-midnight;
  }

  .dropdown.selectDropdown > button {
    @apply relative h-11 rounded-sm border border-gray-100 pb-0 pl-2.5 pr-8 pt-0.5 text-left text-xs text-gray-800;
  }

  .dropdown > button::after {
    @apply absolute right-2 top-5 inline-block h-0 w-0 border-x-2 border-t-2 border-x-transparent text-xl text-midnight content-[''];
  }

  .dropdown.buttonDropdown > button,
  .dropdown.buttonDropdownComplex > button {
    @apply relative h-[30px] rounded-sm border-gray-200 bg-gray-200 py-0 pl-3 pr-8 text-left text-xs text-gray-800 after:top-3.5;
  }

  .dropdown.buttonDropdown > button:disabled,
  .dropdown.buttonDropdownComplex > button:disabled {
    @apply cursor-not-allowed bg-gray-100 text-gray-200 hover:bg-limelight;
  }

  .dropdown.buttonDropdown > button::after,
  .dropdown.buttonDropdownComplex > button::after {
    @apply top-[12px] border-x-[6px] border-t-[6px] border-x-transparent border-t-midnight;
  }

  .dropdown.buttonDropdown > button:disabled::after,
  .dropdown.buttonDropdownComplex > button:disabled::after {
    @apply top-[12px] border-x-[6px] border-t-[6px] border-x-transparent border-y-midnight;
  }

  .dropdown.buttonDropdown > button:hover::after,
  .dropdown.buttonDropdownComplex > button:hover::after {
    @apply border-t-midnight;
  }

  .dropdown > button {
    @apply hover:bg-limelight hover:text-midnight hover:after:text-midnight;
  }

  .dropdown .dropdown-menu {
    @apply hidden;
  }

  .dropdown.open .dropdown-menu {
    @apply absolute left-0 top-full z-50 mx-0 mb-0 mt-2.5 block w-full rounded bg-white bg-clip-padding px-0 py-1.5 text-left text-xs text-gray-800 sm:w-auto;
    box-shadow: 0 3px 5px 1px rgb(0 0 0 / 25%);
  }

  .dropdown.open .dropdown-menu::before,
  .dropdown.open .dropdown-menu::after {
    @apply pointer-events-none absolute bottom-full left-4 h-0 w-0 border border-solid border-transparent content-['_'];
  }

  .dropdown.open .dropdown-menu::before {
    @apply ml-[-9px] border-[9px] border-b-gray-100;
  }

  .dropdown.open .dropdown-menu::after {
    @apply ml-[-7px] border-[7px] border-b-white;
  }

  .dropdown.open .dropdown-menu .dropdown-item {
    @apply z-10 flex w-full px-5 py-1.5 text-left text-sm text-gray-800 hover:bg-limelight;
  }

  .dropdown.open .dropdown-menu .dropdown-item.active {
    @apply bg-limelight;
  }

  .dropdown.open .dropdown-menu .dropdown-item .icon {
    @apply inline-block;
  }

  .dropdown.buttonDropdownComplex.open .dropdown-menu .dropdown-item {
    @apply relative min-w-[220px] px-10;
  }

  .dropdown.buttonDropdownComplex.open
    .dropdown-menu
    .dropdown-item
    .icon-left {
    @apply absolute left-1 top-[5px];
  }

  .dropdown.buttonDropdownComplex.open
    .dropdown-menu
    .dropdown-item
    .icon-right {
    @apply absolute right-2 top-[9px] z-20;
  }

  .dropdown.horizontalView {
    @apply flex items-center gap-x-4;
  }

  .dropdown.horizontalView > span {
    flex: 1 0 200px;
    white-space: initial;
  }

  .dropdown.horizontalView > button,
  .dropdown.horizontalView button > span {
    @apply text-base;
    flex: 0 1 auto;
  }

  .dropdown.horizontalView.errorDropdown {
    @apply grid items-center gap-x-4;
    grid-template-columns: 200px auto;
    grid-template-areas:
      "label button button"
      "....... error error";
  }

  .dropdown.horizontalView.errorDropdown > span {
    grid-area: label;
  }

  .dropdown.horizontalView.errorDropdown > button {
    grid-area: button;
  }

  .dropdown.horizontalView.errorDropdown > .lightErrorMessage {
    grid-area: error;
  }

  .dropdown.horizontalView.open .dropdown-menu {
    left: 216px;
    width: calc(100% - 216px);
    max-height: 283px;
    overflow: scroll;
  }

  .dropdown.horizontalView.errorDropdown.open .dropdown-menu {
    top: 45px;
  }

  .dropdown .search-input {
    margin-bottom: 5px;
  }

  .dropdown .search-input input {
    @apply mt-0 border-0 px-5 py-1.5 text-sm outline-0;
    border-bottom: 1px solid #eee;
  }

  .dropdown .noResults {
    @apply px-5 py-1.5 text-sm;
  }

  @media screen and (max-width: 767px) {
    .dropdown.horizontalView > span {
      flex: 1 0 150px;
    }

    .dropdown.horizontalView.open .dropdown-menu {
      left: 160px;
      width: calc(100% - 160px);
    }

    .dropdown.horizontalView.errorDropdown {
      grid-template-columns: 150px auto;
    }
  }

  @media screen and (max-width: 479px) {
    .dropdown.horizontalView {
      @apply flex-col items-start;
    }

    .dropdown.horizontalView > span {
      flex: 0 1 auto;
    }

    .dropdown.horizontalView.open .dropdown-menu {
      left: 0;
      width: 100%;
    }

    .dropdown.horizontalView.errorDropdown {
      @apply flex flex-col items-start;
    }

    .dropdown.horizontalView.errorDropdown.open .dropdown-menu {
      top: 65px;
    }
  }

  /*
 _     _       _    
| |   (_)     | |   
| |    _ _ __ | | __
| |   | | '_ \| |/ /
| |___| | | | |   < 
\_____/_|_| |_|_|\_\                   
*/
  .link {
    @apply leading-2 tracking-6xl text-gray-800 hover:text-midnight hover:underline;
  }

  .linkSmall {
    @apply text-xs;
  }

  /* .link span, */
  .link svg {
    @apply align-middle;
  }

  .link svg {
    @apply mr-2.5 inline-block fill-midnight;
  }

  .link span + svg {
    @apply ml-2.5 mr-0;
  }

  /*
 _____ _ _   _      
|_   _(_) | | |     
  | |  _| |_| | ___ 
  | | | | __| |/ _ \
  | | | | |_| |  __/
  \_/ |_|\__|_|\___|                   
*/

  .pageHeader {
    @apply border-b border-gray-100 px-2 py-3;
  }

  .primaryTitle {
    @apply pb-0 pt-1 text-base font-normal tracking-xl text-gray-400 sm:text-lg lg:text-xl;
  }

  .secondaryTitle {
    @apply pb-0 pt-1 text-sm font-normal tracking-xl text-midnight sm:text-lg lg:text-xl;
  }

  /*
 _____           _ _   _       
|_   _|         | | | (_)      
  | | ___   ___ | | |_ _ _ __  
  | |/ _ \ / _ \| | __| | '_ \ 
  | | (_) | (_) | | |_| | |_) |
  \_/\___/ \___/|_|\__|_| .__/ 
                        | |    
                        |_|    
  */

  .tooltip {
    @apply relative -mt-1 ml-2.5 inline-block h-3.5 align-middle;
  }

  .tooltip .tooltip-icon {
    @apply inline-block h-3.5 w-3.5 bg-info-tooltip-icon bg-contain bg-no-repeat;
  }

  .tooltip .tooltip-icon:hover {
    @apply cursor-pointer bg-info-tooltip-icon-hover bg-contain bg-no-repeat;
  }

  .tooltip-message {
    @apply invisible absolute z-50 align-middle opacity-10 transition-opacity duration-300;
  }

  .tooltip-message.show {
    @apply visible opacity-100;
  }

  .tooltip-message .tooltip-arrow {
    @apply absolute -top-1 right-6 -ml-1 h-0 w-0 border-[5px] border-b-[5px] border-t-0 border-solid border-transparent border-b-midnight;
  }

  .tooltip-message .tooltip-content {
    @apply w-52 rounded-md bg-midnight p-2 text-center text-xs font-normal tracking-base text-white;
  }
}

/* CUSTOM SCROLLBAR STYLES */
.custom-scrollbar::-webkit-scrollbar-track {
  @apply rounded-md;
}

.custom-scrollbar::-webkit-scrollbar {
  @apply h-1.5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply rounded-md bg-gray-200;
}
