.nodata {
  @apply flex justify-center py-20 text-lg text-gray-400 tracking-xl;
}

// LiveStatus Block

.liveStatus {
  @apply relative h-full sm:bg-white;
}
.liveStatus .ulStatus {
  @apply bg-midnight pt-2;
}
.liveStatus .ulStatus li {
  @apply pt-5 px-2 text-sm text-white sm:px-3 base:text-base;
}
.liveStatus .liveIcon svg {
  @apply h-8 w-8 rounded-full bg-limelight;

  path {
    @apply fill-midnight;
  }

  &[name='CheckCompleteIcon'] path {
    @apply fill-none stroke-midnight;
  }
}

// LicenseByMediaType Block

.licenseByMediaType .stats {
  @apply flex;
}

.licenseByMediaType .stats .statsChart {
  @apply relative w-1/2;
}

.licenseByMediaType .stats .statsBullet {
  @apply flex flex-col flex-1 pl-3 pr-1 justify-center w-1/2;
}

.licenseByMediaType .stats .statsBullet li {
  @apply flex py-3 border-b border-gray-100 text-base items-center;
}

.licenseByMediaType .stats .statsBullet li a {
  @apply flex items-center;
}

// Block

.blockTitle {
  @apply text-base tracking-lg sm:tracking-8xl text-midnight sm:text-lg leading-3;
}

.blockSubTitle {
  @apply text-2xs tracking-8xl text-gray-400 sm:text-xs leading-1;
}
