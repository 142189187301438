/*
    ANALYSIS - TYPE TAB
*/
.analysis .analysisType {
  .analysisTypeHeader {
    @apply px-5 py-4 sm:mb-6 sm:w-2/4 sm:pr-2.5 md:px-5 md:pt-4 lg:px-9 lg:pt-5;

    .period {
      @apply text-lg tracking-lg sm:text-base md:text-lg md:font-normal;

      span {
        @apply text-sm;
      }
    }

    .royalty {
      @apply mt-2 text-2xl font-light;
      
      .moneyValue {
        @apply font-normal;
      }
    }
  }

  .typeStatsContainer {
    @apply mx-5 sm:flex md:flex;

    :global(.highcharts-container) {
      @apply sm:ml-auto sm:mr-5 md:ml-auto md:mr-5;
    }

    .chartLabel {
      @apply absolute right-5 mr-6 top-[138px] w-[230px] text-center text-base;
      transform: translateY(-50%);

      > div {
        @apply m-auto w-7/12;
      }
    }

    .statRow {
      @apply flex pl-2 pt-2 cursor-pointer text-sm py-1 first:border-t-0 hover:text-midnight sm:border-t sm:border-t-gray-100 md:border-t md:border-t-gray-100 sm:py-2.5 md:text-base;

      + .resetBtn {
        @apply py-0 pl-2;
      }

      &.unselected {
        @apply opacity-30;
      }
    }
    
    .info {
      @apply flex-1 pl-4 sm:pl-0;

      i {
        @apply mr-3 inline-block h-3 w-3 rounded-full;
      }
    }

    .royalty {
      @apply inline-block pr-3 text-right sm:block sm:pr-0;
    }
    
    .percent {
      @apply inline-block text-right font-light sm:block md:block md:pr-0;
    }
  }

  .resetBtn {
    svg {
      @apply inline-block h-5 w-5 fill-midnight;
    }

    :hover svg {
      @apply stroke-none;
    }

    span {
      @apply m-0 w-auto;
    }
  }

  .typeDetailsTable {
    @apply mt-4;

    .colHeader {
      @apply cursor-pointer text-xs font-normal text-gray-400 last:text-right hover:text-midnight lg:text-sm;
    }

    .row {
      @apply grid grid-cols-3 border-t border-t-gray-100 p-5 first:border-0 first:py-0;

      div:not(:first-child) {
        @apply last:text-right;
      }
    }
  }
}
