/*
  ANALYSIS - DIGITAL TAB
*/

.analysis .analysisDigital {
  @apply col-span-full bg-white;

  .digitalCol,
  .sourceCol {
    @apply w-[70%] text-left;
  }

  .royaltiesCol {
    @apply w-[25%] text-right hidden md:table-cell;
  }
}
