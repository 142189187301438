/* Higher order class definition */
html {
  font-family: Graphik, "Helvetica Neue", Arial, sans-serif;
}
body {
  @apply text-gray-800 bg-white typography-default;
}

.typography-default {
  @apply text-base leading-3 font-normal;
}

.typography-small {
  @apply text-sm leading-2 font-normal;
}

.typography-label {
  @apply text-xs font-normal;
}

.typography-heading {
  @apply font-semibold;
}

h1 {
  @apply typography-heading text-6xl leading-7;
}
h2 {
  @apply typography-heading text-4xl leading-5;
}
h3 {
  @apply typography-heading text-2xl leading-4;
}
h4 {
  @apply typography-heading text-lg leading-3;
}
h5 {
  @apply typography-heading text-base leading-2;
}
