.termsModal {
  @apply py-6 px-5 w-full max-w-full top-0 mx-auto translate-y-0 base:px-20 sm:top-10 md:top-1/2 base:max-w-[85vw] base:max-h-[80vh] md:translate-y-[-50%] block #{!important};
}
.termsModal > span {
  @apply hidden;
}
.termsModal h2 {
  @apply text-lg mb-4 text-gray-400 font-normal tracking-9xl base:ml-[-52px] base:text-3xl base:mb-8;
}
.termsModal nav button {
  @apply text-sm border-b pb-px mr-4 base:mr-6;
}
.termsModal nav button:hover, .termsModal nav :global(button.active) {
  @apply font-normal pb-0;
}
.termsModal :global(.tab-content) {
  @apply mt-[26px] px-4 pt-4 pb-5 my-3 -mx-2.5 border border-gray-100 overflow-y-scroll base:px-[30px] bg-gray-100;
  max-height: calc(80vh - 250px);
}
.termsModal footer {
  @apply bg-white xs:flex xs:justify-around mb-4 xs:mb-0;
}
.termsModal footer button {
  @apply min-w-[140px] text-xs;
}

/* Tab Content*/

.termsModal :global(.tab-content h1) {
  @apply text-center text-sm text-gray-800 tracking-base font-medium mb-4 base:mb-8;
}
.termsModal :global(.tab-content h2) {
  @apply text-center font-light text-midnight tracking-base ml-0 leading-2 text-xs mb-4 base:mb-[34px] base:text-sm
}
.termsModal :global(.tab-content h3) {
  @apply font-medium leading-4 mb-6 tracking-base text-gray-800 text-xs base:text-sm;
}
.termsModal :global(.tab-content p) {
  @apply mb-6 text-gray-800 font-normal tracking-base ml-0 text-xs leading-2 base:leading-4 base:text-sm;
}
.termsModal :global(.tab-content a) {
  @apply underline;
}
.termsModal :global(.tab-content strong) {
  @apply font-medium;
}