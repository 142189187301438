.checkbox {
  @apply inline-flex cursor-pointer text-center;
}


.checkbox div {
  @apply mx-auto h-5 w-5 border border-gray-200;
}


.checkbox svg {
  @apply relative -left-px -top-px;
}

.checkboxDisable {
  @apply pointer-events-none;

  div {
    @apply bg-gray-100;
  }
}
