.breakDown {
  .productsContainer {
    @apply max-h-[200px] overflow-y-auto text-xs  sm:text-sm;

    .statRow {
      @apply flex justify-between border-b border-b-gray-100 px-5 py-2;

      strong {
        @apply font-medium;
      }

      small {
        @apply text-xs;
      }

      > div {
        @apply w-[37%] first:w-[43%] last:w-[20%] last:text-right;
      }
    }
  }
}