.range {
  @apply relative h-8;
}

.rangeTrack {
  @apply relative mx-2;
}

.trackBase,
.trackColor {
  @apply absolute h-3 top-1.5;
}

.trackBase {
  @apply bg-gray-100 w-full;
}

.trackColor {
  @apply bg-midnight z-10;
}

.leftValue,
.rightValue {
  @apply absolute mt-5;
}

.leftValue {
  @apply left-1.5;
}

.rightValue {
  @apply right-1;
}

.thumb,
.thumb::-webkit-slider-thumb {
  @apply appearance-none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  @apply pointer-events-none absolute h-0 p-0 outline-0 top-[3px] border-0;
}

.thumbLeft {
  @apply z-30 -left-1;
}

.thumbRight {
  @apply z-20 left-1;
}

.thumb::-webkit-slider-thumb {
  @apply bg-white border border-gray-400 border-solid rounded-xl cursor-pointer mt-1 relative h-[22px] w-[22px] pointer-events-auto;
}

.thumb::-moz-range-thumb {
  @apply bg-white border border-gray-400 border-solid rounded-xl cursor-pointer mt-1 relative h-[22px] w-[22px] pointer-events-auto;
}

.thumbRight::-webkit-slider-thumb {
  @apply bg-gray-100;
}

.thumbRight::-moz-range-thumb {
  @apply bg-gray-100;
}
