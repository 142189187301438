.confirmationSwitchCurrency {
  @apply rounded px-9 pb-8 pt-9 sm:max-w-[400px] #{!important};
}
.confirmationSwitchCurrency > span {
  @apply hidden;
}
.confirmationSwitchCurrency h3.title {
  @apply text-base font-medium tracking-5xl text-gray-400;
}
.confirmationSwitchCurrency p {
  @apply block pb-10 font-light tracking-10xl;
}
.confirmationSwitchCurrency .buttonContainer {
  @apply flex justify-between;
}
.confirmationSwitchCurrency button {
  @apply w-[150px] py-1;
}
.confirmationSwitchCurrency button span {
  @apply text-xs;
}
