.modal {
  @apply fixed top-0 left-0 w-full h-full z-10;
}

.modal .backdrop {
  @apply cursor-pointer fixed w-full top-0 bottom-0 opacity-50 z-10 bg-black;
}

.modal .contentContainer {
  @apply bg-white px-4 pt-4 pb-6 absolute z-50 w-full top-0 left-0 max-h-screen overflow-y-auto;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
}

.modal .contentContainer {
  @apply sm:left-0 sm:right-0 sm:top-1/2 sm:h-auto sm:w-full sm:max-w-[640px] sm:m-auto sm:px-7 sm:pt-6 sm:translate-y-[-50%] sm:pb-10;
}

.modal .footer {
  @apply flex justify-between mt-8;
}

.modal .close {
  @apply cursor-pointer absolute top-2 right-4 w-12 h-12;
}

.modal .content h3 {
  @apply text-3xl text-gray-400 tracking-11xl font-light mb-5 pr-7;
}

.modal .content h4 {
  @apply text-xl text-midnight tracking-10xl font-light mb-5 pr-7;
}

.modal .footer .button {
  @apply text-sm px-12 pt-2 pb-1 min-w-[150px] rounded-sm;
}

.modal .footer .button.primaryCta {
  @apply bg-green-600 hover:bg-midnight;
}

.modal .footer .button.outlineCta {
  @apply text-gray-400 hover:text-gray-400;
}
