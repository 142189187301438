.errorPage {
  @apply text-center h-full;
}

.errorContent {
  @apply px-6 pt-36;
}

.errorPage h1 {
  @apply text-gray-200 font-normal text-3xl mb-10 pb-8 h-24 relative tracking-9xl leading-5 sm:text-6xl;
}

.errorPage h1:after {
  @apply content-[''] block w-36 h-[1.6px] bg-gray-200 absolute bottom-0 -ml-[70px] left-1/2;
}

.errorPage span {
  @apply text-xl font-light leading-4 pb-12 text-gray-200;
}

.errorPage span a {
  @apply underline-offset-4 underline;
}

.roundedIcon {
  @apply w-32 h-32 pl-8 mx-auto;
}

.roundedIcon svg circle {
  @apply fill-gray-200;
}

.roundedIcon svg path {
  @apply fill-gray-800;
}
