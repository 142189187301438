.horPeriodSelector {
  @apply flex items-center sm:px-3 sm:py-3 md:px-5 md:py-4;
}

.horPeriodSelector .sliderContainer button,
.horPeriodSelector a {
  @apply text-sm lg:text-base;
}

.horPeriodSelector .allPeriodsBtn {
  @apply h-7 px-5 py-0.5 text-xs sm:text-sm;
}

.horPeriodSelector .currentPeriod {
  @apply font-medium text-midnight;
}

.horPeriodSelector .sliderContainer {
  @apply w-[60vw] flex-1 overflow-hidden pl-7 pr-5 pt-1 sm:pl-20 md:w-[70vw] md:overflow-hidden;
}

.horPeriodSelector :global(.cleanCta) {
  @apply py-0;
}

.horPeriodSelector :global(.slick-slider > *) {
  @apply align-middle;
}

.horPeriodSelector :global(.slick-arrow) {
  @apply inline-block h-7 w-7 fill-midnight;
}

.horPeriodSelector :global(.slick-prev) {
  @apply mt-[-14px] -scale-100;
}

.horPeriodSelector :global(.slick-disabled) {
  display: none !important;
}
