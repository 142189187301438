.selectClientsBtn {
  @apply mr-2;
}
.selectClientsBtn > button {
  @apply w-full;
}
.selectClientsOptionIcon {
  @apply inline-block mt-1 ml-2 w-[14px] h-4;
}
.deleteBtn {
  @apply inline-block pl-0 pr-0 w-[14px] h-4 mr-2;
}
.selectClientsBtn span {
  @apply max-w-[195px] inline-block overflow-hidden text-ellipsis;
}
.selectClientsBtn img{
  @apply w-[25px] h-[15px] ml-1 inline-block;
}