.search {
  @apply sm:max-w-[53%] h-14 w-full block relative;
  input {
    @apply h-7 text-xs sm:h-8 sm:text-base;
  }
}

.search input {
  @apply mt-0 block border-0 border-b border-b-gray-100 bg-transparent pl-1.5 pr-16 text-sm lg:text-base tracking-base sm:mb-0.5;
}

.search input:focus {
  @apply border-b-midnight outline outline-0;
}

.search .iconSearch {
  @apply w-4 h-4 top-2 right-1 sm:right-2 absolute inline-block bg-search-icon bg-cover;
}

.search .iconClear {
  @apply absolute right-1 top-2 inline-block h-4 w-4 cursor-pointer sm:right-2;
}

.search .iconClear span:before,
.search .iconClear span:after {
  @apply absolute left-0 top-1/2 -mt-px h-[2px] w-full bg-midnight content-[''] lg:inline-block;
}

.search .iconClear span:before {
  transform: rotate(45deg);
}

.search .iconClear span:after {
  transform: rotate(-45deg);
}

.searchSummary .matching {
  @apply inline-block text-xs italic sm:min-w-[88px] sm:text-base;
}

.search .searchSummary .clear {
  @apply ml-2 cursor-pointer text-xs text-midnight sm:text-base;
}

.search .backToTop {
  @apply absolute right-0 top-[35px] mb-2 ml-2 cursor-pointer text-2xs text-midnight sm:text-xs;
}