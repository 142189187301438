.analysisTrack .tabTable .trackCol {
  @apply w-[33%] text-left;
}

.analysisTrack .tabTable .artistCol {
  @apply w-[19%] text-left hidden md:table-cell;
}

.analysisTrack .tabTable .royaltiesCol {
  @apply w-[16%] text-right hidden md:table-cell;
}

.analysisTrack .tabTable .downloadUnitsCol {
  @apply w-[16%] text-right hidden md:table-cell;
}

.analysisTrack .tabTable .streamingUnitsCol {
  @apply w-[16%] text-right hidden md:table-cell;
}