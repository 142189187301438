.tooltipBmg {
    font-size: 0.7rem;
    font-weight: normal;
    white-space: normal;
    letter-spacing: normal;
    text-align: center;
    opacity: 1;
    line-height: 1.5;
    max-width: 200px;
    border-radius: 6px;
    z-index: 700000;
}

div[id="digitalFlashTrendChart"].tooltipBmg {
    @apply max-w-[340px] sm:max-w-[440px] opacity-100;
}
