.breakDown {
  .typeStatsContainer {
    @apply md:flex;

    .columnChartContainer {
      @apply md:hidden;

      hr {
        @apply -mt-4 border-t border-gray-100;
      }
    }

    .pieChartContainer {
      @apply relative hidden md:flex md:min-w-[226px] md:w-1/2 md:justify-center;
    }

    .chartLabel {
      @apply absolute right-0 left-0 top-[113px] text-center text-base;
      transform: translateY(-50%);

      span {
        @apply block w-full text-center;
      }
    }

    .statRow {
      @apply flex pl-2 pt-2 text-sm first:border-t-0 md:border-t md:border-t-gray-100;
    }

    .info {
      @apply flex-1;

      i {
        @apply mr-3 inline-block h-3 w-3 rounded-full;
      }
    }

    .royalty {
      @apply inline-block pr-3 text-right md:block md:pr-0;
    }

    .percent {
      @apply inline-block text-right font-light md:block;
    }
  }
}