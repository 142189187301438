/*
  ANALYSIS - SOURCE TAB
*/

.analysis  .analysisSource {
  @apply col-span-full;
  
  .sourceCol {
    @apply w-[70%] text-left;
  }

  .royaltiesCol {
    @apply w-[25%] text-right hidden md:table-cell;
  }
}
