.scroll {
  flex: 1;
  overflow: auto;
}

.scroll {
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
}

.scroll::-webkit-scrollbar {
  @apply w-2;
}

.scroll::-webkit-scrollbar-track {
  @apply rounded bg-white;
}

.scroll::-webkit-scrollbar-thumb {
  @apply rounded border border-solid border-gray-200 bg-gray-200;
}

.table {
  @apply w-full;
  .td {
    @apply flex py-1 first:pt-4 last:pb-4 md:table-cell md:py-4 md:align-top;
    .columnName {
      @apply font-light text-gray-400 md:hidden;
    }
  }
}

// first column
.songColumn {
  @apply pl-4 pr-2 text-left font-medium md:w-[20%] md:font-normal;
}

.composersColumn {
  @apply flex flex-col pl-5 pr-6 text-left md:table-cell md:w-[20%] md:pl-4;
}

.projectColumn {
  @apply flex flex-col pl-5 pr-6 text-left md:table-cell md:min-w-[30%] md:pl-4;
}

.feeColumn {
  @apply flex justify-between whitespace-nowrap pl-5 pr-6 text-right md:table-cell md:w-[20%] md:pl-4;
}

.statusColumn {
  @apply flex justify-between pl-5 pr-6 text-left md:table-cell md:w-[15%] md:pl-4;
}

.royalityColumn {
  @apply flex justify-between whitespace-nowrap pl-5 pr-6 text-left md:table-cell md:w-[15%] md:pl-2;
}

.dateColumn,
.periodColumn {
  @apply flex justify-between whitespace-nowrap pl-5 pr-6 text-left md:table-cell md:w-[10%] md:pl-2;
}
